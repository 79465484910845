<template>
  <div :class="$screen.width < 768 ? 'pa-2' : 'pa-4'">
    <h1
      style="
        font-size: 2rem;
        line-height: 3.25rem;
        font-weight: bold;
        text-align: left;
      "
      :class="$screen.width < 768 ? 'pl-2' : 'pl-4'"
    >
      Datenschutzbestimmungen
    </h1>
    <p style="display: block" class="pt-0 mt-0 mb-0 content-paragraph">
      Stand 01. Mai 2019
    </p>
    <div class="row mt-0 content-paragraph">
      <div class="flex lg12 md12 sm12 xs12">
        <p style="font-weight: bold; color: black" class="mb-2">
          1. Allgemeneines
        </p>
        <p>
          Die unter www.recycling.de und allen hierauf ggf. verweisenden
          weiteren Internetadressen zugängliche Internetseite („Website“) wird
          von der Zentek GmbH & Co. KG („Zentek“) betrieben; die Kontaktdaten
          entnehmen Sie bitte dem Impressum. Zentek nimmt den Datenschutz und
          die diesem Zweck dienenden gesetzlichen Verpflichtungen sehr ernst. In
          dieser Datenschutzerklärung erfahren Sie, welche Daten auf der Website
          bei der Kontaktaufnahme zu Zentek und in unseren Geschäftsabläufen
          erhoben, verarbeitet, in welcher Weise genutzt werden und wie Zentek
          den Schutz Ihrer Daten gewährleistet.
        </p>
        <p>
          Wir verarbeiten personenbezogene Daten, die wir für die Erfüllung von
          Verträgen oder zur Durchführung vorvertraglicher Maßnahmen von unseren
          Kunden oder anderen Betroffenen erhalten. Zudem verarbeiten wir –
          soweit für die Erbringung unserer Dienstleistung erforderlich –
          personenbezogene Daten, die wir aus öffentlich zugänglichen Quellen
          (z.B. Schuldnerverzeichnisse, Handels- und Vereinsregister, Presse,
          Internet) zulässigerweise gewinnen oder die uns von sonstigen Dritten
          (z.B. Wirtschaftsauskunfteien) berechtigt übermittelt werden.
        </p>
        <p>
          Für weiterführende Informationen oder Fragen zu der Verwendung Ihrer
          Daten, die durch diese Datenschutzerklärung nicht beantwortet werden,
          wenden Sie sich bitte an den Datenschutzbeauftragten:
        </p>
        <p>
          Hans-Ulrich Förster, Zentek Services GmbH & Co. KG,
          Ettore-Bugatti-Str. 6-14, 51149 Köln
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          2. Personenbezogene Daten
        </p>
        <p>
          Personenbezogene Daten erlauben die Feststellung der Identität des
          Nutzers dieser Website bzw. des Kunden. Hierunter fallen u.a. Name,
          Vorname, Anschrift, Telefonnummer und E-Mail-Adresse. Die Nutzung
          unserer Website ist grundsätzlich möglich, ohne dass Sie Ihre
          Identität ganz oder teilweise offenlegen müssen. Erst bei einer
          vertraglichen oder vorvertraglichen Maßnahme oder der Kontaktaufnahme
          zu Zentek, z.B. über die Website, ist die Offenlegung Ihrer Identität
          und die Preisgabe personenbezogener Daten erforderlich. Ohne die
          Offenlegung Ihrer Identität und die Preisgabe personenbezogener Daten
          ist eine Bearbeitung Ihrer Anfrage durch Zentek nicht möglich. Es ist
          deshalb Ihre freie Entscheidung, ob Sie zu Zwecken der Kontaktaufnahme
          mit uns personenbezogene Daten an uns übermitteln wollen.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          3. Automatische Datenverarbeitung auf der Website
        </p>
        <p>
          Die Website wird von einem hierauf spezialisierten Computer („Server“)
          ausgeliefert, der automatisch bei jedem Aufruf der Website
          Informationen erhebt und in Protokolldateien („Log-Files“) speichert.
          Hierbei handelt es sich um (1) den verwendeten Browser einschließlich
          der Versionsnummer, (2) das verwendete Betriebssystem, (3) die Adresse
          der von Ihnen zuvor besuchten Internetseite („Referrer URL“), (4) die
          Adresse des Computers, mit der Sie auf die Website zugegriffen haben
          („IP-Adresse“) sowie (5) Uhrzeit und Datum des Abrufs dieser Website.
        </p>
        <p>
          Die vorgenannten Informationen werden von Ihrem Browser übermittelt,
          sofern Sie dies nicht durch eine abweichende Konfiguration Ihres
          Browsers oder eine von Ihnen eingesetzte Sicherheitssoftware
          unterbinden. Zentek kann die in den Log-Files vom Server gespeicherten
          Daten einer bestimmten Person nicht zuordnen. Es erfolgt auch keine
          Zusammenführung der Daten in den Log-Files des Servers mit anderen
          Datenquellen, die bei uns ggf. zur Verfügung stehen.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          4. Verwendung Ihrer Daten
        </p>
        <p>
          Wir verarbeiten personenbezogene Daten im Einklang mit den
          Bestimmungen der EU-Datenschutz-Grundverordnung (DS-GVO) und dem
          Bundesdatenschutzgesetz (BDSG) in folgenden Fällen:
        </p>
        <ol
          style="
            list-style: decimal;
            margin-block-start: 1em;
            margin-block-end: 1em;
          "
          class="pl-4"
        >
          <li>nach Ihrer Einwilligung</li>
          <li>
            aufgrund eines Vertrages oder zur Durchführung vorvertraglicher
            Maßnahmen (ggf. auf Anfrage)
          </li>
          <li>
            die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich
          </li>
          <li>
            die Verarbeitung ist erforderlich, um lebenswichtige Interessen der
            betroffenen Person oder einer anderen natürlichen Person zu schützen
          </li>
          <li>
            die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich,
            die im öffentlichen Interesse liegt
          </li>
          <li>
            die Verarbeitung ist zur Wahrung unserer berechtigten Interessen
            oder eines Dritten erforderlich, sofern nicht die Interessen oder
            Grundrechte und Grundfreiheiten der betroffenen Person, die den
            Schutz personenbezogener Daten erfordern, überwiegen.
          </li>
        </ol>
        <p>
          Nehmen Sie über die Website Kontakt zu uns auf, werden die von Ihnen
          abgefragten personenbezogenen Daten ausschließlich zum Zweck der
          Bearbeitung Ihrer Kontaktaufnahme erhoben, gespeichert und genutzt,
          also insbesondere zur Bearbeitung Ihrer allgemeinen Anfrage an uns
          über das Kontaktformular, Ihrer Anfrage nach einer Registrierung für
          einen geschützten Zugang oder zur Bearbeitung und Abwicklung einer von
          Ihnen über die Website übermittelten Vertragsanfrage oder eines zuvor
          abgeschlossenen Vertrags.
        </p>
        <p>
          Auch Ihre E-Mail-Adresse verwenden wir ausschließlich zu dem Ihrer
          Kontaktaufnahme über die Website dienenden Zweck. Nur falls dies von
          Ihnen ausdrücklich gewünscht wird, übermitteln wir an Sie im Rahmen
          der von Ihnen erteilten Einwilligung auch über den Zweck der
          Kontaktaufnahme hinausgehende E-Mails mit allgemeinen Informationen
          und Werbung an Sie. Ihre Einwilligung in diese weitergehende
          Verwendung Ihrer E-Mail-Adresse können Sie selbstverständlich
          jederzeit per E-Mail oder schriftlich an die im Impressum genannte
          Anschrift widerrufen, worauf wir Sie auch in jeder E-Mail mit
          allgemeinen Informationen oder werblichem Inhalt gesondert hinweisen
          werden.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          5. Weitergabe der Daten
        </p>
        <p>
          Unsere Mitarbeiter erhalten Zugriff auf Ihre Daten, wenn sie sie zur
          Erfüllung vertraglicher und gesetzlicher Pflichten brauchen. Auch von
          uns eingesetzte Dienstleister und Erfüllungsgehilfen können zu diesen
          Zwecken Daten erhalten, wenn insoweit die Datenschutzbestimmungen
          gewahrt werden. Dies sind Unternehmen in den Kategorien Entsorgung,
          Entsorgungs-Dienstleistung, Beratung sowie Vertrieb und Marketing.
        </p>
        <p>
          Im Hinblick auf die Datenweitergabe an Empfänger außerhalb der Zentek
          ist zu beachten, dass Daten grundsätzlich nur weitergegeben werden,
          wenn gesetzliche Bestimmungen dies vorschreiben, ein
          Auftragsverarbeitungsverhältnis oder eine Einwilligung vorliegt.
        </p>
        <p>
          Eine Übermittlung Ihrer Daten in ein Drittland oder an eine
          internationale Organisation erfolgt nach den gesetzlichen
          Bestimmungen. Der Umfang der Übermittlung ist beschränkt auf das zur
          Zweckerreichung erforderliche Minimum.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          6. Speicherdauer
        </p>
        <p>
          Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange
          dies für die Erfüllung unserer vertraglichen und gesetzlichen
          Pflichten (handels- und steuerrechtliche Aufbewahrungspflichten – in
          der Regel zwei bis zehn Jahre -, gesetzliche Verjährungsvorschriften –
          3 bis 30 Jahre -) erforderlich ist. Danach werden Daten regelmäßig
          gelöscht.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          7. Erstellung von Nutzungsprofilen
        </p>
        <p>
          Um die Website zu verbessern sowie zu Zwecken der Werbung und zur
          Marktforschung analysieren wir das Verhalten der Nutzer dieser
          Website. Zu diesem Zweck erstellt Zentek anonymisierte
          Nutzungsprofile, sofern Sie dem nicht widersprechen. Zentek stellt
          sicher, dass die anonymisierten Nutzungsprofile nicht mit Ihren
          personenbezogenen Daten zusammengeführt werden. Der Widerruf gegen die
          Erstellung anonymisierter Nutzungsprofile kann per E-Mail oder
          schriftlich an die im Impressum genannte Person gerichtet werden.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          8. Datenschutzrechte
        </p>
        <p>
          Wir erteilen Ihnen jederzeit unentgeltlich und unverzüglich gemäß den
          gesetzlichen Bestimmungen Auskunft über die zu Ihnen durch uns
          gespeicherten personenbezogenen Daten. Wenden Sie sich zu diesem Zweck
          bitte an die im Impressum genannten Personen oder an den
          Datenschutzbeauftragten.
        </p>
        <p>
          Jede betroffene Person hat außerdem das Recht auf Berichtigung, das
          Recht auf Löschung, das Recht auf Einschränkung der Verarbeitung, das
          Recht auf einzelfallbezogenen Widerspruch, das Recht auf Widerspruch
          gegen eine Verarbeitung von Daten für Zwecke der Direktwerbung sowie
          das Recht auf Datenübertragbarkeit.
        </p>
        <p>
          Betroffene Personen können jederzeit Beschwerden bei einer zuständigen
          Datenschutzaufsichtsbehörde vorbringen.
        </p>
        <p>
          Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten
          kann jederzeit uns gegenüber widerrufen werden. Dies gilt auch für den
          Widerruf von Einwilligungserklärungen, die vor der Geltung der DSGVO,
          also vor dem 25. Mai 2018, uns gegenüber erteilt worden sind.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          9. Hinweis zu sozialen Netzwerken
        </p>
        <p>
          Wenn Sie Zentek in den sozialen Netzwerken Facebook, Instagram, Xing,
          YouTube und LinkedIn (Betreiber) aufrufen, sind deren
          Datenschutzerklärungen für die Verarbeitung entscheidend. Diese
          Erklärungen sind über die Webseiten der Betreiber jederzeit abrufbar.
          Sie sollten sich deshalb mit der im Einzelfall geltenden
          Datenschutzerklärung über Ihre Rechte – auch in Bezug auf Cookies –
          bei dem jeweiligen Betreiber informieren.
        </p>
        <p>
          Sollten Sie in einem sozialen Netzwerk Inhalte an uns weitergeben oder
          sich in sonstiger Weise gegenüber Zentek zu erkennen geben,
          verarbeiten wir personenbezogene Daten von Ihnen. Einige der sozialen
          Netzwerke bieten darüber hinaus eine Nachrichtenfunktion an über die
          Sie direkt mit uns in Kontakt treten können. In diesen Fällen
          verarbeiten wir Ihre personenbezogenen Daten entsprechend dieser
          Erklärung
        </p>
        <p>
          Hinsichtlich Facebook ist das Folgende zu beachten. Der Zusatz für
          Verantwortliche (https://www.facebook.com/legal/controller_addendum),
          gilt insofern, als er durch diese Bezugnahme ausdrücklich Bestandteil
          von Nutzungsbedingungen für Facebook-Produkte wird, wie z. B. die
          Nutzungsbedingungen für Facebook Business-Tools (jegliche solche
          Nutzungsbedingungen sind „Nutzungsbedingungen für Umfasste Produkte“
          und die umfassten Facebook-Produkte „Umfasste Produkte“). Zentek und
          Facebook Ireland arbeiten mit diesem Zusatz, um die jeweiligen
          Verantwortlichkeiten für die Erfüllung der Verpflichtungen gemäß
          DS-GVO hinsichtlich der gemeinsamen Verarbeitung festzulegen (wie in
          den Nutzungsbedingungen für Umfasste Produkte). Zentek stellt
          Betroffenen insoweit diese Informationen wegen der Verwendung
          umfasster Produkte bereit: Facebook Ireland ist ein gemeinsam
          Verantwortlicher der gemeinsamen Verarbeitung; die gemäß Artikel 13
          Abs. 1 lit. a) und b) DS-GVO erforderlichen Informationen befinden
          sich in der Datenrichtlinie von Facebook Ireland unter
          <a href="https://www.facebook.com/about/privacy" target="_blank"
            >https://www.facebook.com/about/privacy</a
          >. Dort finden sich auch Informationen dazu, wie Facebook Ireland
          personenbezogene Daten verarbeitet, einschließlich der
          Rechtsgrundlage, auf die Facebook Ireland sich stützt, und der
          Möglichkeiten zur Wahrnehmung der Rechte betroffener Personen gemäß
          Artikel 15-20 der DS-GVO gegenüber Facebook Ireland.
        </p>
        <p style="font-weight: bold; color: black" class="mt-4 mb-2">
          10. Hinweis zu Google Analytics
        </p>
        <p>
          Unser Internetauftritt verwendet „Google Analytics“, einen
          Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain
          View, CA 94043, Vereinigte Staaten von Amerika („Google”). Wir erheben
          und speichern über diesen Webanalysedienst Daten, aus denen unter
          Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Die so
          erstellten Nutzungsprofile dienen der Auswertung des
          Besucherverhaltens, um die auf unseren Internetseiten angebotenen
          Leistungen bedarfsgerecht gestalten und verbessern zu können. Google
          Analytics verwendet so genannte „Cookies“, also kleine Textdateien,
          die auf Ihrem Computer gespeichert werden und eine Analyse der
          Benutzung unserer Internetseiten ermöglichen.
        </p>
        <p>
          Die durch ein Cookie erzeugten Informationen über Ihre Benutzung
          unseres Internetauftritts werden in der Regel an einen Server von
          Google in den Vereinigten Staaten von Amerika übertragen und dort
          gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf
          unserem Internetauftritt – wir haben auf unserem Internetauftritt
          Google Analytics um den Code „_anonymizedIP ();“ erweitert – wird Ihre
          IP Adresse von Google jedoch innerhalb der Mitgliedstaaten der
          Europäischen Union oder anderer Vertragsstaaten des Abkommens über den
          Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird
          die volle IP-Adresse an einen Server von Google in den Vereinigten
          Staaten von Amerika übertragen und dort gekürzt. In unserem Auftrag
          wird Google diese Informationen benutzen, um Ihre Nutzung unserer
          Internetseiten auszuwerten, um Reports über die
          Internetseitenaktivitäten zusammenzustellen und um weitere mit der
          Nutzung unserer Internetseiten und der Internetnutzung verbundene
          Dienstleistungen gegenüber uns als Betreiber unserer Internetseiten zu
          erbringen.
        </p>
        <p>
          Die im Rahmen von Google Analytics von Ihrem Internetbrowser
          übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt. Auch unter Pseudonym geführte Nutzungsprofile werden
          ohne ausdrückliche, gesondert erklärte Einwilligung Ihrerseits nicht
          mit personenbezogenen Daten in Verbindung gebracht. Gleichwohl können
          Sie die Speicherung der Cookies durch eine entsprechende Einstellung
          Ihrer Browsersoftware verhindern. Wir weisen Sie jedoch darauf hin,
          dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen
          unseres Internetauftritts vollumfänglich werden nutzen können.
        </p>
        <p>
          Darüber hinaus können Sie die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung unseres Internetauftritts bezogenen Daten durch
          Google verhindern, indem Sie das unter
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
          >
            Download-Seite für das Browser-Add-on zur Deaktivierung von Google
            Analytics</a
          >
          erhältliche Browser-Plugin herunterladen und installieren.
        </p>
        <p>
          Datenschutzhinweise von Google können Sie unter
          <a href="www.google.de/intl/de/policies/privacy/" target="_blank"
            >www.google.de/intl/de/policies/privacy/</a
          >
          sowie unter
          <a
            href="http://www.google.com/intl/de/analytics/privacyoverview.html"
            target="_blank"
            >Google Marketing Platform - Unified Advertising and Analytics</a
          >
          abrufen. Alternativ zum Browser-Plugin oder innerhalb von Browsern auf
          mobilen Geräten klicken Sie bitte auf den folgenden Link, um ein
          Opt-Out-Cookie zu setzen, der die Erfassung durch Google Analytics
          innerhalb dieser Website zukünftig verhindert (dieses Opt-Out-Cookie
          funktioniert nur in diesem Browser und nur für diese Domain, löschen
          Sie Ihre Cookies in diesem Browser, müssen Sie diesen Link erneut
          klicken):
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=de"
            target="_blank"
            >Google Analytics deaktivieren</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="css" scoped>
@media (max-width: 767px) {
  h1 {
    font-size: 1rem !important;
  }
  .content-paragraph {
    font-size: 0.7rem !important;
    text-align: left !important;
    overflow-wrap: break-word !important;
    word-break: break-word !important;
  }
}
</style>
